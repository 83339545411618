import { template as template_c481d94c88d64a00a4fd8c5f2a1183c1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c481d94c88d64a00a4fd8c5f2a1183c1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
