import { template as template_706856d149c54c3e960ee81b23cc6517 } from "@ember/template-compiler";
const FKText = template_706856d149c54c3e960ee81b23cc6517(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
